import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const PhoneMask = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    // Expressão regular para validar o formato do número de telefone
    const phoneRegex = /^\(\d{2}\) \d{1}.\d{4}-\d{4}$/;
    if (!phoneRegex.test(value)) {
      setError('Por favor, insira um número de telefone válido.');
    } else {
      setError('');
    }
  };

  return (
    <div>
      <InputMask 
        mask="(99) 9.9999-9999" 
        maskChar=" " 
        placeholder="(12) 3.4567-8901" 
        value={phoneNumber}
        onChange={handlePhoneChange}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PhoneMask;